import { useLiveQuery } from 'dexie-react-hooks';

import { Message, Topic, dbStore } from './db';

export type MergedTopic = Exclude<Topic, 'lastMessageId'> & Partial<Message>;

export const useTopics = () => {
    return useLiveQuery(async () => {
        const topics = await dbStore.topics.limit(10).toArray();
        return await Promise.all(topics.map((topic) => {
            return dbStore.messages.where('tkey').equals(topic.id).last().then((msg) => {
                return { ...msg, ...topic };
            }, (e) => {
                console.error('select last message failed', e);
                return topic;
            });
        }));
    }, [], []) as MergedTopic[];
};

export const useTopic = (key) => {
    return useLiveQuery(() => {
        if (Number.isInteger(key)) {
            return dbStore.topics.get(key).catch(e => {
                console.error('select special topic failed', e);
                return {};
            });
        } else {
            return {};
        }
    }, [key], {}) as Topic;
};

export const useMessages = (key) => {
    return useLiveQuery(() => {
        if (Number.isInteger(key)) {
            return dbStore.messages.where('tkey').equals(key).toArray().catch(e => {
                console.error('select message failed', e);
                return [];
            });
        } else {
            return [];
        }
    }, [key], []) as Message[];
};
import Dexie, { Table } from 'dexie';

export interface Topic {
  id?: number;
  title: string;
}

export interface Message {
  id?: number;
  tkey: string;
  role: string;
  created: number;
  content: string;
}

export class SophiaDB extends Dexie {
  topics!: Table<Topic, number>; 
  messages!: Table<Message, number>; 

  constructor() {
    super('SophiaDB');
    this.version(1).stores({
        topics: '++id',
        messages: '++id, tkey, role, created',
    });
  }
}

// @ts-ignore
export const dbStore = window.dbStore = new SophiaDB();
import { h } from 'preact';
import { useCallback, useMemo, useReducer, useRef } from 'preact/hooks';
import { route } from 'preact-router';

import MessageItem from './message';
import { useMessages, useTopic } from '../../hook';
import { Message, Topic, dbStore } from '../../hook/db';

interface ErrorResponse {
	"error": {
		"message": "We could not parse the JSON body of your request. (HINT: This likely means you aren't using your HTTP library correctly. The OpenAI API expects a JSON payload, but what was sent was not valid JSON. If you have trouble figuring out how to fix this, please send an email to support@openai.com and include any relevant code you'd like help with.)",
		"type": "invalid_request_error",
		"param": null,
		"code": null
	}
}
interface Response {
	id: string;
	created: number;
	role: string;
	content: string;
}

class CustomError extends Error {

}

const ask = async (messages) => {
	try {
		const result = await fetch("https://chat.gitai.me/v1/chat/completions", {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				// 'Authorization': 'Bearer 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',
			},
			body: JSON.stringify({
				model: 'gpt-3.5-turbo',
				"user": "chatcmpl-6rTWiaVSzeyqqY3XpiIc1mSh1ZuyB",
				messages: messages.filter(msg => msg.role === 'user' || msg.role === 'assistant').map(({ role, content }) => ({ role, content }))
			})
		});

		const data = await result.json();
		if (data.error as ErrorResponse) {
			console.error(data);
			throw new CustomError(data.error.type || 'network error');
		}

		if (!data.id) {
			console.error(data);
			throw new CustomError('unknown');
		}

		return { created: data.created, ...data.choices[0].message } as Response;
	} catch (e) {
		console.error(e);
		if (!(e instanceof CustomError)) {
			e = new CustomError('network error');
		}
		return { created: new Date().getTime() / 1000 | 0, role: 'system', "content": e.message }

	}

}

const useGPTConnect = (chatId: number | undefined = undefined): [Topic, Message[], (msg) => any] => {
	const chatIdRef = useRef<undefined | number | Promise<number>>(chatId);
	useMemo(() => {
		console.log('update cached chat id');
		chatIdRef.current = chatId;
	}, [chatId]);
	const topic = useTopic(chatIdRef.current);
	const messages = useMessages(chatIdRef.current);
	const messagesRef = useRef([]);
	messagesRef.current = messages || [];

	const updateDB = useCallback(async (newMsg) => {
		let chatId: number;
		if (chatIdRef.current === undefined) {
			chatId = await (chatIdRef.current = dbStore.topics.add({ title: 'Untitled...' }));
			route(`/${chatId}`, false)
		} else if (typeof chatIdRef.current === 'number') {
			chatId = chatIdRef.current;
		} else if ('then' in chatIdRef.current) {
			chatId = await chatIdRef.current;
		} else {
			throw new Error('invalid chatid');
		}
		chatIdRef.current = chatId;
		await dbStore.messages.add({ tkey: chatId as any, ...newMsg });
	}, [chatIdRef]);

	const send = useCallback(async (msg) => {
		const question = { role: "user", content: msg, created: new Date().getTime() / 1000 | 0 };
		await updateDB(question);
		const answer = await ask((messagesRef.current || []).slice().concat([question]));
		await updateDB(answer);
	}, [messagesRef, updateDB]);
	return [topic, messages, send] as any;
}

const Chat = ({ chatId }) => {
	const inputRef = useRef();
	const [topic, messagegs, send] = useGPTConnect(Number(chatId) || undefined);

	const onSendClick = useCallback(() => {
		// @ts-ignore
		if (inputRef.current?.value && inputRef.current?.value.trim().length > 0) {
			// @ts-ignore
			send(inputRef.current.value);
			// @ts-ignore
			inputRef.current.value = '';
		}
	}, [inputRef, send]);

	const onKeyDown = useCallback((event) => {
		if (event.keyCode === 13 && (event.shiftKey || event.metaKey)) {
			onSendClick();
		}
	}, [onSendClick]);

	const onTopicChanged = useCallback((event) => {
		if (event.target.innerText !== topic.title) {
			dbStore.topics.update(topic.id, { title: event.target.innerText || 'Untitled...' });
		} 
	}, [topic])

	return (
		<div data-cid={chatId} class="chat-area flex-1 flex flex-col">
			<div class="flex-3">
				<h2 class="text-xl py-1 mb-8 border-b-2 border-gray-200">{
					topic.id ? (<span>Chatting about <b contentEditable onBlur={onTopicChanged} dangerouslySetInnerHTML={{__html: topic.title}}></b></span>) : (<span>New Chat</span>)
				}</h2>
			</div>
			<div class="messages flex-1 overflow-auto">
				{messagegs.map((message: any) => <MessageItem {...message} />)}
			</div>
			<div class="flex-2 pt-4 pb-10">
				<div class="write bg-white shadow flex rounded-lg">
					<div class="flex-1">
						<textarea ref={inputRef}
							class="w-full block outline-none py-4 px-4 bg-transparent"
							placeholder="Type a message..." autofocus rows={3} onKeyDown={onKeyDown}></textarea>
					</div>
					<div class="flex-2 w-16 p-2 flex content-center items-center">
						<div class="flex-1">
							<button class="bg-blue-400 w-10 h-10 rounded-full inline-block" onClick={onSendClick}>
								<span class="inline-block align-text-bottom">
									<svg fill="none" stroke="currentColor" stroke-linecap="round"
										stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
										class="w-4 h-4 text-white">
										<path d="M5 13l4 4L19 7"></path>
									</svg>
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>)
}


export default Chat;
import { h } from 'preact';
import { useMemo } from 'preact/hooks';

Prism.plugins.autoloader.loadLanguages(['python', 'javascript', 'cpp']);

marked.setOptions({
	highlight: function (code, lang) {
		if (Prism.languages[lang]) {
			return Prism.highlight(code, Prism.languages[lang], lang);
		} else {
			return code;
		}
	}
});

const MessageItem = ({ created, role, content }: any = {}) => {
	const [fromNow, lll] = useMemo(() => {
		const instance = dayjs(created * 1e3);
		const lll = instance.format('lll');
		if (instance.isToday()) {
			return [instance.fromNow(), lll];
		}
		return lll;
	}, [created]);
	switch (role) {
		case 'assistant': {
			return (<div class="message mb-4 flex">
				{/* <div class="flex-2">
					<div class="w-12 h-12 relative">
						<img class="w-12 h-12 rounded-full mx-auto"
							src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII" alt="chat-user" />
						<span
							class="absolute w-4 h-4 bg-gray-400 rounded-full right-0 bottom-0 border-2 border-white"></span>
					</div>
				</div> */}
				<div class="flex-1 px-2">
					<div class="inline-block bg-gray-300 rounded p-2 px-6 text-gray-700">
						{/* @ts-ignore */}
						<span dangerouslySetInnerHTML={{ __html: marked.parse(content) }}></span>
					</div>
					<div class="pl-4"><small class="text-gray-500" title={lll}>{fromNow}</small></div>
				</div>
			</div>)
		}
		case 'user': {
			return (<div class="message me mb-4 flex text-right">
				<div class="flex-1 px-2">
					<div class="inline-block bg-blue-600 rounded p-2 px-6 text-white">
						<span dangerouslySetInnerHTML={{ __html: content }}></span>
					</div>
					<div class="pr-4"><small class="text-gray-500" title={lll}>{fromNow}</small></div>
				</div>
			</div>)
		}
		default: {
			return (<div class="message mb-4 flex">
				<div class="flex-1 px-2">
					<div class="inline-block bg-red-400 rounded p-2 px-6 text-gray-700">
						<span dangerouslySetInnerHTML={{ __html: content }}></span>
					</div>
					<div class="pl-4"><small class="text-gray-500" title={lll}>{fromNow}</small></div>
				</div>
			</div>)
		}
	}

}

export default MessageItem;
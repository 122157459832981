import { h } from 'preact';
import { useTopics, MergedTopic } from '../../hook';
import { Link } from 'preact-router/match';
import { useMemo } from 'preact/hooks';

const Topic = ({ id, title, role, content, created }: MergedTopic) => {
    const [fromNow, lll] = useMemo(() => {
        const instance = dayjs(created * 1e3);
        const lll = instance.format('lll');
        if (instance.isToday()) {
            return [instance.fromNow(), lll];
        }
        return lll;
    }, [created]);
    return (
        <Link activeClassName={'shadow-md bg-slate-400 disabled'} href={`/${id}`} class={"entry cursor-pointer transform hover:scale-105 duration-300 transition-transform bg-white mb-4 rounded p-4 flex shadow-sm"}>
            {/* <div class="flex-2">
					<div class="w-12 h-12 relative">
						<img class="w-12 h-12 rounded-full mx-auto"
							src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50" alt="chat-user" />
						<span
							class="absolute w-4 h-4 bg-gray-400 rounded-full right-0 bottom-0 border-2 border-white"></span>
					</div>
				</div> */}
            <div class="px-2">
                <div class="truncate w-32"><span class="text-gray-800">{title}</span>
                </div>
                {
                    content ?
                        <div class='line-clamp-2'><small class="text-gray-500">{role}:</small><small class="text-gray-600" dangerouslySetInnerHTML={{ __html: content }}></small></div>
                        : undefined
                }
            </div>
            {
                created ?
                    <div class="absolute float-right right-0 mr-5">
                        <div><small class="text-gray-500" title={lll}>{fromNow}</small></div>
                        {/* <div>
                    <small
                        class="text-xs bg-red-500 text-white rounded-full h-6 w-6 leading-6 text-center inline-block">
                        23
                    </small>
                </div> */}
                    </div>
                    : undefined
            }
        </Link>

    )
}

const Sidebar = () => {
    const topics = useTopics();
    return (<div class="sidebar hidden lg:flex w-1/4 flex-2 flex-col pr-6">
        <div class="search flex-2 pb-6 px-2">
            <input type="text"
                class="outline-none py-2 block w-full bg-transparent border-b-2 border-gray-200"
                placeholder="Search" />
        </div>
        <div class="flex-1 h-full overflow-auto px-2">
            <Topic id={'' as unknown as number} title="+ New Chat" />
            {topics.map(topic => <Topic {...topic} />)}
        </div>
    </div>)
}

export default Sidebar;
import { h } from 'preact';
import { Route, Router } from 'preact-router';

import Header from './header';
import Sidebar from './sidebar';
import Chat from './chat';

const App = () => {
	return (
		<div class="w-full h-screen dark">
			<div class="flex h-full">
				<div class="flex-1 bg-gray-100 w-full h-full">
					<div class="main-body container-full m-auto w-11/12 h-full flex flex-col">
						{/* <Header /> */}
						{/* <div class="main flex-1 flex flex-col"> */}
							{/* <div class="hidden lg:block heading flex-2">
								<h1 class="text-3xl text-gray-700 mb-4">ChatGPT</h1>
							</div> */}
	
							<div class="flex-1 flex h-full">
								<Sidebar />
								<Router>
									<Route path="/:chatId" component={Chat} />
									<Route default component={Chat} />
								</Router>
							</div>
						{/* </div> */}
					</div>
				</div>
			</div>
		</div>
	)
};

export default App;
